import React, {useMemo, useCallback, useState} from 'react';
import {Link} from 'gatsby';
import {useBreakpoint} from 'gatsby-plugin-breakpoints';
import {motion} from 'framer-motion';
import {ButtonSecondary} from '../components/Button';
import {MainHeroHeader} from './Headings';
import {
  fadeElement,
  fade,
  pageVariants,
  pageTransition,
} from '../components/Transitions';

import BlueLine from '../images/blue-line.svg';
import PinkLine from '../images/pink-line.svg';

let selectedTimeout;

const Hero = ({
  headingTop,
  headingHighlight,
  headingBottom,
  buttonOne,
  buttonTwo,
}) => {
  const [selectedButton, setSelectedButton] = useState(false);

  const {isLarge, isXLarge} = useBreakpoint();

  const doSetSelectedButton = useCallback(
    nextSelectedButton => {
      if (selectedTimeout) clearTimeout(selectedTimeout);
      selectedTimeout = setTimeout(
        () => {
          setSelectedButton(nextSelectedButton);
        },
        !selectedButton ? 10 : 150
      );
    },
    [selectedButton, setSelectedButton]
  );

  const headingTopLevel = useMemo(() => {
    switch (selectedButton) {
      case 'One':
        return buttonOne.headingTopLevel;
      case 'Two':
        return buttonTwo.headingTopLevel;
      default:
        return headingTop;
    }
  }, [selectedButton, headingTop, buttonOne, buttonTwo]);

  const headingHighlightLevel = useMemo(() => {
    switch (selectedButton) {
      case 'One':
        return buttonOne.headingHighlight;
      case 'Two':
        return buttonTwo.headingHighlight;
      default:
        return headingHighlight;
    }
  }, [selectedButton, headingHighlight, buttonOne, buttonTwo]);

  const headingBottomLevel = useMemo(() => {
    switch (selectedButton) {
      case 'One':
        return buttonOne.headingBottomLevel;
      case 'Two':
        return buttonTwo.headingBottomLevel;
      default:
        return headingBottom;
    }
  }, [selectedButton, headingBottom, buttonOne, buttonTwo]);

  return (
    <div className={`hero ${selectedButton && `btn${selectedButton}Hover`}`}>
      <motion.div
        variants={pageVariants}
        transition={pageTransition}
        animate="visible"
        className="flex-none w-full max-w-screen-xl m-auto hero__wrap">
        <div className="pl-6 pr-6 hero__block hero__top lg:pl-12 2xl:pl-0">
          <MainHeroHeader
            headingTopLevel={headingTopLevel}
            headingHighlightLevel={headingHighlightLevel}
            headingBottomLevel={headingBottomLevel}
          />
          {isXLarge && (
            <div className="hidden hero__lines md:block">
              <img src={BlueLine} alt="Blue Line" />
              <img src={PinkLine} alt="Pink Line" />
            </div>
          )}
        </div>
        {isLarge ? (
          <div className="hero__block hero__bottom">
            <Link
              to={`/${buttonOne?.entry[0]?.uri}`}
              onMouseEnter={() => doSetSelectedButton('One')}
              onMouseLeave={() => doSetSelectedButton(false)}>
              <ButtonSecondary
                label={buttonOne?.linkLabel}
                icon={buttonOne?.icon[0]}
                className="hidden hero__button hero__button--one md:flex"
              />
            </Link>
            <Link
              to={`/${buttonTwo?.entry[0]?.uri}`}
              onMouseEnter={() => doSetSelectedButton('Two')}
              onMouseLeave={() => doSetSelectedButton(false)}>
              <ButtonSecondary
                label={buttonTwo?.linkLabel}
                icon={buttonTwo?.icon[0]}
                className="hidden hero__button hero__button--two md:flex"
              />
            </Link>
          </div>
        ) : (
          <div className="absolute bottom-0 w-full">
            <Link to={`/${buttonOne?.entry[0]?.uri}`}>
              <div
                className="p-6 bg-gray-300 bg-gradient-to-r from-blue to-lightblue"
                dangerouslySetInnerHTML={{__html: buttonOne?.linkLabel}}
              />
            </Link>
            <Link to={`/${buttonTwo?.entry[0]?.uri}`}>
              <div
                className="p-6 bg-red-400 bg-gradient-to-r from-pink to-lightpink"
                dangerouslySetInnerHTML={{__html: buttonTwo?.linkLabel}}
              />
            </Link>
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default Hero;
